import _ from 'lodash'
import { makeAutoObservable, runInAction,toJS } from 'mobx'
import { message } from 'antd'
import { escape2Html } from '@/utils/utility'
import * as homeApi from '@/services/home'

import common from './common'

export class RecruitStore {
  data = []

  recruitOption:any = {}
  newCate:any[] = []
  newsList: any[] = []
  recruitTotle: number = 0
  recruitList: any[] = []
  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }
  async getBannerData() {
    common.startLoading()
    try {
      const json = await homeApi.getBanner(6)
      runInAction(() => {
        this.data = _.get(json, 'data')
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }



  // 获取招聘列表
  async getRecruitList(params: any) {
    common.startLoading()
    try {
      const json = await homeApi.getRecruitList(params)
      const formData = _.get(json, 'data') ?_.get(json, 'data.positionList').map((item:any)=> {
        const departments = this.recruitOption.departments.find((i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.department? i.value === item.department : {}
        })
     
        const educations = this.recruitOption.educations.find((i: any) =>{
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.education ? i.value === item.education : {}
        })
        const locations = this.recruitOption.locations.find((i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.location ? i.value === item.location: {}
        })
        const positionTypes = this.recruitOption.positionTypes.find((i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.positionType ? i.value === item.positionType: {}
        })
        return {
          ...item,
          department: departments.name,
          location: locations.name,
          education: educations.name,
          number: item.number || 0,
          positionType: positionTypes.name,
          content: escape2Html(_.get(item, 'content'))
        }
      }): []
      runInAction(() => {
        this.recruitTotle = _.get(json, 'data.total')
        this.recruitList = formData
      })
    } catch (e) {
      console.log(e)
      // do nothing
    } finally {
      common.endLoading()
    }
  }

    // 获取人才分类
  async getRecruitOption() {
      common.startLoading()
      try {
        const json = await homeApi.getRecruitOption()
  
        runInAction(() => {
          this.recruitOption = _.get(json, 'data')
        })
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading()
      }
    }



}

export default new RecruitStore()
