import request from "@/utils/request";
import qs from 'qs'
import {  HOME_API, NEWS_API, RECRUIT_API,PRODUCT_API } from '@/constants/api'
import { stringifyQs } from '@/utils/utility'


// 获取banner
export function getBanner(type: number) {
  return request(`${HOME_API}/banners?type=${type}`)
}

// 获取公司信息
export function getCompanyInfo() {
  return request(`${HOME_API}/companyInfo?`)
}
// 获取公司网站信息
export function getCompanySite() {
  return request(`${HOME_API}/site`)
}

// 获取产品类目
export function getProductCate(limit:number) {
  return request(`${PRODUCT_API}/catalog?limit=${limit}`)
}

// 获取产品类目
export function getProjects() {
  return request(`${PRODUCT_API}/projects`)
}
// 获取新闻类目
export function getNewsCate() {
  return request(`${NEWS_API}/catalog?type=news`)
}

// 获取公司历程
export function getHistory() {
  return request(`${HOME_API}/history`)
}
// 获取公司文化
export function getCompanyCulture() {
  return request(`${HOME_API}/companyCulture`)
}


// 获取新闻最新报道
export function getNewsTop(catalogId: string) {
  return request(`${NEWS_API}/top?catalogId=${catalogId}&limit=8`)
}

// 获取招聘top
export function getRecruitTop() {
  return request(`${RECRUIT_API}/top`)
}

// 获取招聘列表
export function getRecruitList(params:any) {
  return request(`${RECRUIT_API}/pageList?${qs.stringify(params)}`)
}

// 获取招聘Option
export function getRecruitOption() {
  return request(`${RECRUIT_API}/queryOptions`)
}