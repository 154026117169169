import _ from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { message } from "antd";

import * as productApi from "@/services/product";
import * as homeApi from "@/services/home";
import common from "./common";

interface Product {
  catalogId: number;
  catalogName: string;
  introduction: string;
  thumbnail: string;
  list?: Array<Item>;
}

interface Item {
  catalogId: number;
  name: string;
  introduction: string;
  thumbnail: string;
}

export class ProductStore {
  banners:any[] = [];
  data: Array<Product> = [];
  companyInfo = {};
  listCount = 0;
  productList = []
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  
    // 获取banner
    async getBannerData() {
      common.startLoading()
      try {
        const json = await homeApi.getBanner(4)
        runInAction(() => {
          this.banners = _.get(json, 'data')
        })
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading()
      }
    }

  // 获取首页数据
  async getProductData() {
    common.startLoading();
    try {
      const json = await productApi.getCatalog(2);
      console.log(_.get(json, "data"));
      runInAction(() => {
        this.data = _.get(json, "data");
      });
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading();
    }
  }
  // 获取首页数据
  async getProductList() {
    common.startLoading();
    try {
      let list: any[] = [];
      const res = await Promise.all(
        this.data.map((item) => productApi.getProductList(item.catalogId))
      );

      for (let i = 0; i < res.length; i++) {
        const _item = {
          ...this.data[i],
          list: res[i].data,
        };
        list.push(_item);
      }
      runInAction(() => {
        this.data = list;
      });
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading();
    }
  }

    // 获取产品分类数据
    async getProductCateList(catalogId:number) {
      common.startLoading();
      try {
        const json = await productApi.getProductList(catalogId)
  
        runInAction(() => {
          this.productList = _.get(json, "data");
        });
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading();
      }
    }
}

export default new ProductStore();
