import { news } from "@/assets/images/news/news.png";
import _ from "lodash";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { message } from "antd";

import * as newsApi from "@/services/news";
import * as homeApi from "@/services/home";

import common from "./common";

interface News {
  catalogId: number;
  catalogName: string;
  introduction: string;
  thumbnail: string;
  list?: Array<Item>;
}

interface Item {
  catalogId: number;
  name: string;
  introduction: string;
  thumbnail: string;
}

interface ServerConfig {
  apps: LabelValue<number>[];
  pages: LabelValue<number>[];
  channels: LabelValue<number>[];
  adTypes: LabelValue<number>[];
  goalTypes: LabelValue<number>[];
  chargeModes: LabelValue<number>[];
}

export class NewsStore {
  banners:any[] = []
  data: Array<News> = [];
  companyInfo = {};
  listCount = 0;
  newsList = [];
  newsTotal = 0

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  
  // 获取banner
  async getNewsBannerData() {
    common.startLoading()
    try {
      const json = await homeApi.getBanner(5)
      runInAction(() => {
        this.banners = _.get(json, 'data')
     
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }
  // 获取首页数据
  async getNewsData() {
    common.startLoading();
    try {
      const json = await newsApi.getCatalog("news");
      console.log(_.get(json, "data"));
      runInAction(() => {
        this.data = _.get(json, "data");
      });
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading();
    }
  }

  // 获取新闻数据
  async getNewsList() {
    common.startLoading();
    try {
      let list: any[] = [];
      const res = await Promise.all(
        this.data.map((item) => newsApi.getList(item.catalogId, 6, 1))
      );

      for (let i = 0; i < res.length; i++) {
        const _item = {
          ...this.data[i],
          list: res[i].data?.news || [],
          total: res[i].data?.total || 0
        };
        list.push(_item);
      }
      console.log(list)
      runInAction(() => {
        this.data = list;
      });
    } catch (e) {
      console.log(e)
      // do nothing
    } finally {
      common.endLoading();
    }
  }

    // 获取单个新闻
  async getNews(catalogId: any, page: number) {
      common.startLoading();
      try {
        const json = await newsApi.getList(catalogId, 10, page)
  
        runInAction(() => {
          this.newsTotal = _.get(json, "data.total", 0);
          this.newsList = _.get(json, "data.news", []);
        });
      } catch (e) {
        console.log(e)
        // do nothing
      } finally {
        common.endLoading();
      }
    }
}

export default new NewsStore();
