import { useContext } from "react";
import { MobXProviderContext } from "mobx-react";

import common from "./common";
// TODO
import homeStore from "./home";
import productStore from "./product";
import newsStore from "./news";
import annoStore from "./anno";
import introduceStore from "./introduce";
import recruitStore from "./recruit";
import ArticleStore from "./article";

const stores = {
  common,
  homeStore,
  productStore,
  newsStore,
  annoStore,
  introduceStore,
  recruitStore,
  ArticleStore,
};

export default stores;

export function useStores(): typeof stores {
  return useContext(MobXProviderContext) as typeof stores;
}
