import _ from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { escape2Html } from '@/utils/utility'
import { message } from "antd";

import * as newsApi from "@/services/news";

import common from "./common";

interface News {
  catalogId: number;
  catalogName: string;
  introduction: string;
  thumbnail: string;
  list?: Array<Item>;
}

interface Item {
  catalogId: number;
  name: string;
  introduction: string;
  thumbnail: string;
}


export class ArticleStore {
  data: any = {};
  companyInfo = {};
  listCount = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // 获取首页数据
  async getArticle(id: string) {
    common.startLoading();
    try {
      const json = await newsApi.getDetail(id);
      runInAction(() => {
        this.data = {
          ..._.get(json, "data"),
          title:  _.get(json, "data.title"),
          content:  escape2Html(_.get(json, "data.content", ''))
        };;
      });
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading();
    }
  }
}

export default new ArticleStore();
