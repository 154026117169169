import _ from 'lodash'
import { makeAutoObservable, runInAction,toJS } from 'mobx'
import { message } from 'antd'
import { escape2Html } from '@/utils/utility'
import * as homeApi from '@/services/home'

import common from './common'


export class IntroduceStore {
  data = []
  companyInfo:any = {}
  productCate:any[] = []
  listCount = 0
  recruitOption:any = {}
  newCate:any[] = []
  newsList: any[] = []
  recruitTop: any[] = []
  historyList: any[] = []
  projects: any[] = []
  companyCulture: any = {}
  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  // 获取首页数据
  async getIntroduceBannerData() {
    common.startLoading()
    try {
      const json = await homeApi.getBanner(3)
      runInAction(() => {
        this.data = _.get(json, 'data')
     
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }
  // 获取产品类目
  async getProduct() {
    common.startLoading()
    try {
      const json = await homeApi.getProductCate(6)
      runInAction(() => {
        this.productCate = _.get(json, 'data')
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }
  // 获取公司项目
  async getCompanyProjects() {
    common.startLoading()
    try {
      const json = await homeApi.getProjects()
      runInAction(() => {
        this.projects = _.get(json, 'data')
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }
  // 获取公司简介
  async getIntroduce() {
    common.startLoading()
    try {
      const json = await homeApi.getCompanyInfo()
      const formData = {
        ..._.get(json, 'data'),
        content: escape2Html(_.get(json, 'data.content'))
      }
      runInAction(() => {
        this.companyInfo = formData
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }

  // 获取人才招聘
  async getRecruit() {
    common.startLoading()
    try {
      const json = await homeApi.getRecruitTop()
      const formData =_.get(json, 'data').map((item:any)=> {
        console.log(toJS(item))
        const departments = this.recruitOption.departments.find((i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.department? i.value === item.department : {}
        })
     
        const educations = this.recruitOption.educations.find((i: any) =>{
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.education ? i.value === item.education : {}
        })
        const locations = this.recruitOption.locations.find((i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.location ? i.value === item.location: {}
        })
        const positionTypes = this.recruitOption.positionTypes.find((i: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          return item.positionType ? i.value === item.positionType: {}
        })
        return {
          ...item,
          department:departments.name,
          location:locations.name,
          education:educations.name,
          number: item.number || 0,
          positionType: positionTypes.name
        }
      })
      runInAction(() => {
        this.recruitTop = formData
      })
    } catch (e) {
      console.log(e)
      // do nothing
    } finally {
      common.endLoading()
    }
  }

    // 获取人才分类
  async getRecruitOption() {
      common.startLoading()
      try {
        const json = await homeApi.getRecruitOption()
  
        runInAction(() => {
          this.recruitOption = _.get(json, 'data')
        })
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading()
      }
    }
    // 获取公司历程
  async getCompanyHistory() {
      common.startLoading()
      try {
        const json = await homeApi.getHistory()
  
        runInAction(() => {
          this.historyList = _.get(json, 'data')
        })
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading()
      }
    }

  // 获取公司文化
  async getCulture() {
      common.startLoading()
      try {
        const json = await homeApi.getCompanyCulture()
        runInAction(() => {
          this.companyCulture = escape2Html(_.get(json, 'data.content'))
        })
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading()
      }
    }

    // 获取新闻动态
    async getNewsCate() {
      common.startLoading()
      try {
        const json = await homeApi.getNewsCate()
        runInAction(() => {
          this.newCate = _.get(json, 'data')
        })
      } catch (e) {
        // do nothing
      } finally {
        common.endLoading()
      }
    }

  // 获取新闻动态
  async getTopNews(catalogId:string) {
    common.startLoading()
    try {
      const json = await homeApi.getNewsTop(catalogId)
      runInAction(() => {
        this.newsList = _.get(json, 'data')
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }

}

export default new IntroduceStore()
