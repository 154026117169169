import _ from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { message } from "antd";

import * as newsApi from "@/services/news";
import * as homeApi from "@/services/home";
import common from "./common";

interface News {
  catalogId: number;
  catalogName: string;
  introduction: string;
  thumbnail: string;
  newsId: number;
  title: string;
  content: string;
  createTime: string;
}

interface Cate {
  catalogId: number;
  catalogName: string;
  introduction: string;
  thumbnail: string;
  newsId: number;
  title: string;
  content: string;
}

interface Detail {
  news: Array<News>;
  total: number;
  page: number;
}

export class AnnoStore {
  banners:any[] = []
  data: Array<Cate> = [];
  detail: Detail = {
    news: [],
    total: 0,
    page: 1,
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
   // 获取banner
   async getAnnosBannerData() {
    common.startLoading()
    try {
      const json = await homeApi.getBanner(8)
      runInAction(() => {
        this.banners = _.get(json, 'data')
     
      })
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading()
    }
  }

  // 获取首页数据
  async getNewsData() {
    common.startLoading();
    try {
      const json = await newsApi.getCatalog("gonggao");
      console.log(_.get(json, "data"));
      runInAction(() => {
        this.data = _.get(json, "data");
      });
    } catch (e) {
      // do nothing
    } finally {
      common.endLoading();
    }
  }

  async getAnnoDetail(catalogId?: any, page?: number) {
    let id = this.data[0]?.catalogId;
    if (catalogId) {
      id = catalogId;
    }
    const json = await newsApi.getList(id, 10, page || 1);
    runInAction(() => {
      this.detail = _.get(json, "data");
    });
  }
}

export default new AnnoStore();
