import * as Sentry from '@sentry/browser'
import { notification } from 'antd'

interface RequestParams {
  codeField?: string;
  msgField?: string;
  isSuccess?: (body: any) => boolean;
  shouldLogin?: (body: any) => boolean;
  hideNotify?: boolean;
  isBinary?: boolean
}

// 提示
function notify(message: string) {
  notification.error({
    message: '错误',
    description: message
  })
}

export default async function request(url: string, options: RequestInit = {}, params: RequestParams = {}): Promise<any> {
  const {
    codeField = 'code',
    msgField = 'message',
    isSuccess,
    shouldLogin,
    hideNotify = false,
    isBinary = false

  } = params
  const defaultOptions: RequestInit = { credentials: 'include' }
  options = { ...defaultOptions, ...options }


  if ((['POST', 'PUT', 'DELETE'] as any[]).includes(options.method)) {
    let defaultContentType = !isBinary ? {
      'Content-Type': 'application/json;charset=utf-8'
    } : undefined

    options.headers = {
      credentials: 'omit',
      Accept: 'application/json',
      ...defaultContentType,
      ...options.headers,
    }
    if (typeof options.body === 'object' && (!isBinary)) {
      options.body = JSON.stringify(options.body)
    }
  }
  let authorization = ''
  const project = (url.match(
    /\/\/cms\.haiziwang\.com\/c\/(pop\/search-collection|admin-pop\/point-collection|admin\/search-collection)/
  ) || [])[1]
  if (project) {
    try {
      authorization = localStorage.getItem(`token-${project}`) || ''
    } catch (e) {}
  }
  if (authorization) {
    options.headers = Object.assign(options.headers || {}, { Authorization: authorization })
  }

  let responseBody = ''
  let traceID = ''
  try {
    const response: Response = await window.fetch(url, options)
    traceID = response.headers.get('x-trace-id') || ''    // add_header 'Access-Control-Expose-Headers' 'x-trace-id';
    responseBody = await response.clone().text()
    const json = await response.json()
    if (typeof isSuccess === 'function' ? isSuccess(json) : (+json.code === 0 || +json.code === 1001 || +json.errno === 0 || +json.code == 6600028)) {
      return json
    } else if (typeof shouldLogin === 'function' ? shouldLogin(json) : (+json.code === 1005 || +json.code === 500011 || +json.errno === 1024 || +json.code === 1024)) {
  
    } else {
      throw json
    }
  } catch (e) {
    console.log(e)
    if (e === 'login') {
      throw e
    }
    if (!(e instanceof Error) && e[msgField]) {
      if(hideNotify !== true){
        // notify(e[msgField])
      }
      // 系统异常或调用外部接口异常
      // errorCode 不规范，此情况暂不上报
      // if (process.env.REACT_APP_ENV === 'production' && traceID && (e[codeField] < 0 || e[codeField] > 9000)) {
      //   Sentry.withScope(scope => {
      //     scope.setExtras({
      //       url,
      //       options,
      //       responseBody,
      //       trace_id: traceID,
      //       desc: '系统异常或调用外部接口异常'
      //     })
      //     Sentry.captureException(e)
      //   })
      // }
    } else if (e.message) {
      // notify(e.message)
    }else {
      if(hideNotify !== true){
        if(url.includes('/search-collection/common/userInfo')){
          // notify('获取用户信息失败')
        }else{
          // notify('网络错误')
        }
      }
      if (process.env.REACT_APP_ENV === 'production') {
        // Sentry.withScope(scope => {
        //   scope.setExtras({
        //     url,
        //     options,
        //     responseBody,
        //     desc: '网络错误'
        //   })
        //   Sentry.captureException(e)
        // })
      }
    }
    
    throw e
  }
}
