import _ from 'lodash'
import qs from 'qs'

export function secureRegExp(str = ''): RegExp {
  try {
    return new RegExp(str)
  } catch (e) {
    console.error('new RegExp Error', e)
    return /(?:)/
  }
}

export function loginWithHref(href: string) {
  if (window.top.location.href !== window.self.location.href) {
    window.top.location.href = href
  } else {
    window.location.href = href
  }
}
export function login(body: any) {
  // TODO
  const href = 'https://site.haiziwang.com'
  loginWithHref((_.get(body, 'data') && typeof _.get(body, 'data') === 'string' && /^http/.test(body.data)) ? body.data : href)
}

export function stringifyQs(options: any) {
  return qs.stringify(options, {
    filter: (prefix, value) => {
      // 过滤null ''
      if (value === null || value === '') {
        return
      }
      return value
    }
  })
}

// export function htmlUnEscape(str: any) {
//   const unescapes: any = {
//       '&amp;' : '&',
//       '&lt;' : '<',
//       '&gt;' : '>',
//       '&quot;' : '"',
//       '&#39;' : "'",

//   }
//   const reEscapeHtml = new RegExp(/&:amp|lt|gt|quot|#39;/g);
//   return (str && reEscapeHtml.test(str) ? str.replace(reEscapeHtml, function(entity: any){
//     return unescapes[entity];
//   }) : (str || ''))
// }


export function escape2Html(str: string) {
  var arrEntities:any = {'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t]
})}

export function getUrlQuery(search: string){
  return qs.parse(search,{ ignoreQueryPrefix: true })
}