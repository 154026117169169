import { observable, action, runInAction, makeAutoObservable } from 'mobx'

export class CommonStore {
  loading = false

  private loadingCount = 0
  private loadingTimer: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  startLoading(immediate = true) {
    this.loadingCount += 1
    if (this.loading) {
      return
    }
    if (immediate) {
      this.loading = true
      return
    }
    if (this.loadingTimer === null) {
      this.loadingTimer = window.setTimeout(() => {
        runInAction(() => {
          this.loading = true
        })
      }, 200)
    }
  }

  endLoading() {
    if (this.loadingCount > 0) {
      this.loadingCount -= 1
    } else {
      this.loadingCount = 0
    }
    if (this.loadingCount === 0) {
      this.loading = false
      this.clearTimer()
    }
  }

  endAllLoading() {
    this.loadingCount = 0
    this.loading = false
    this.clearTimer()
  }

  private clearTimer() {
    if (this.loadingTimer !== null) {
      window.clearTimeout(this.loadingTimer)
      this.loadingTimer = null
    }
  }
}

export default new CommonStore()
