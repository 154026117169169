import React, { Suspense, lazy, useEffect } from "react";
import { Provider, observer } from "mobx-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

import { ROUTE_BASE } from "./constants/constants";
import stores from "./stores";
import { StyleProvider, legacyLogicalPropertiesTransformer } from "@ant-design/cssinjs";

// TODO
const Home = lazy(() => import("./containers/HomePage"));
const NewsPage = lazy(() => import("./containers/NewsPage"));
const NewsList = lazy(() => import("./containers/NewsList"));
const Introduce = lazy(() => import("./containers/Introduce"));
const Recruit = lazy(() => import("./containers/Recruit"));
const Announcement = lazy(() => import("./containers/Announcement"));
const Products = lazy(() => import("./containers/Products"));
const ProductList = lazy(() => import("./containers/ProductList"));
const ArticleDetail = lazy(() => import("./containers/ArticleDetail"));

function App() {
  // TODO

  return (
    <Provider {...stores}>
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <ConfigProvider
          locale={zhCN}
          theme={{
            token: {
              colorPrimary: "#24833A",
            },
          }}
        >
          <Router basename={ROUTE_BASE}>
            <Suspense fallback={null}>
              <Switch>
                {/* TODO */}
                <Route exact path="/" component={Home} />
                <Route exact path="/news" component={NewsPage} />
                <Route exact path="/news/:cateId" component={NewsList} />
                <Route exact path="/introduce" component={Introduce} />
                <Route exact path="/recruit" component={Recruit} />
                <Route exact path="/announcement" component={Announcement} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/products/:cateId" component={ProductList} />
                <Route exact path="/article/:newsId" component={ArticleDetail} />
              </Switch>
            </Suspense>
          </Router>
        </ConfigProvider>
      </StyleProvider>
    </Provider>
  );
}

export default observer(App);
