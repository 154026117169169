import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "", // TODO
    release: "", // --sentry release--
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
