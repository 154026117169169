import request from "@/utils/request";

import { NEWS_API } from "@/constants/api";
import { stringifyQs } from "@/utils/utility";

import mockRequest from "./mock/mockRequest";
import { serverConfigBody, pointListBody, pointDetailBody } from "./mock/point";

// 获取类目
export function getCatalog(type: "gonggao" | "news" | "jobs") {
  return request(`${NEWS_API}/catalog?type=${type}`);
}

// 获取类目
export function getTop(catalogId: number, limit: number) {
  return request(`${NEWS_API}/top?limit=${limit}&catalogId=${catalogId}`);
}

// 获取类目
export function getList(catalogId: number, limit: number, page: number) {
  return request(`${NEWS_API}/pageList?limit=${limit}&catalogId=${catalogId}&page=${page}`);
}

// 获取类目
export function getDetail(newsId: number) {
  return request(`${NEWS_API}/detail?newsId=${newsId}`);
}
