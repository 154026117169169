// TODO
const SHFARM_ROOT = "//sh-farm.cn/sh-farm";

export const Banner_API = `${SHFARM_ROOT}/index/banners`;

export const Product_Catelog_API = `${SHFARM_ROOT}/product/catalog`;
export const Product_List_API = `${SHFARM_ROOT}/product/list`;
export const HOME_API = `${SHFARM_ROOT}/index`
export const NEWS_API = `${SHFARM_ROOT}/news`
export const PRODUCT_API = `${SHFARM_ROOT}/product`
export const RECRUIT_API = `${SHFARM_ROOT}/recruit`

