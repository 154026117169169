import request from "@/utils/request";

import { PRODUCT_API } from "@/constants/api";
import { stringifyQs } from "@/utils/utility";

import mockRequest from "./mock/mockRequest";
import { serverConfigBody, pointListBody, pointDetailBody } from "./mock/point";

// 获取类目
export function getCatalog(limit: number) {
  return request(`${PRODUCT_API}/catalog?limit=${10}`);
}

// 获取类目
export function getProductList(catalog: number) {
  return request(`${PRODUCT_API}/list?catalog=${catalog}`);
}
